<template>
  <v-layout class="copy-link-slide" column align-center justify-space-around>
    <h2>Invite players to the game by sharing this link</h2>

    <v-btn class="font-weight-bold" flat x-large @click="click">
      <svg-icon name="copy-regular" />
      <template v-if="clicked">Copied!</template>
      <template v-else>Copy link</template>
    </v-btn>
  </v-layout>
</template>

<style lang="scss">
.copy-link-slide {
  height: 100%;
  border: 4px solid $primary_accent_color;
  border-radius: 16px;
  min-width: 230px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: radial-gradient(
    137.87% 137.87% at 49.24% 9.52%,
    #2c2c2c 0%,
    #000000 100%
  );
  * {
    color: $color-white;
    font-size: 22px;
    line-height: 34px;
  }
  & > h2 {
    padding: 1rem;
    padding-top: min(3rem, 5vw);
    text-align: center;
    a {
      white-space: nowrap;
    }
  }
  & > button {
    width: 8em;
    padding: 2rem;
    text-transform: none;
    border-radius: 4px;
    background-color: $secondary-card-text;
    svg {
      margin-right: 1ex;
      width: 1em;
      height: 1em;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex"
import { copyToClipboard } from "@/helpers"
export default {
  data: () => ({
    clicked: false
  }),

  computed: {
    ...mapGetters(["urlID"]),

    link() {
      return `${window.location.origin}/login/${this.urlID}?auth=0` // TODO refactor
    }
  },

  methods: {
    click() {
      copyToClipboard(this.link)
      this.clicked = true
    }
  }
}
</script>
