var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "copy-link-slide",
      attrs: { column: "", "align-center": "", "justify-space-around": "" },
    },
    [
      _c("h2", [_vm._v("Invite players to the game by sharing this link")]),
      _c(
        "v-btn",
        {
          staticClass: "font-weight-bold",
          attrs: { flat: "", "x-large": "" },
          on: { click: _vm.click },
        },
        [
          _c("svg-icon", { attrs: { name: "copy-regular" } }),
          _vm.clicked ? [_vm._v("Copied!")] : [_vm._v("Copy link")],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }